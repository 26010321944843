import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FormQuestions, FormSectionContainer, WarningSummarySection } from '@form';
import { scrollToTopOfPage, setTitle } from '@util/browser';
import Api from '@services/api';
import { Button } from 'nhsuk-react-components';
import { createSectionWarningListFromProps } from '@util/warning-summary';
import { getSubmissionSectionPayload } from '@util/api-payloads';
import { isEmpty } from 'lodash';

const FormQuestionSection = forwardRef((props, ref) => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        multipleSectionIndex: undefined,
        showRemoveButton: undefined,
        showSaveAndExitButton: undefined,
        showSaveAndSkipButton: undefined,
        showSaveAndGoToSummaryButton: undefined,
        showSkipButton: undefined,
        title: undefined,
        warningList: []
    });

    useEffect(() => {
        const index = params.index;
        const warningList = createSectionWarningListFromProps(props);

        setState({
            multipleSectionIndex: index ? Number(index) : undefined,
            showRemoveButton:
                props.sectionSpec.allow_multiple &&
                !props.isFirstLoad &&
                !(
                    props.sectionSpec?.multiple_sections?.must_have &&
                    props.multipleSectionCount === 1
                ),
            showSaveAndExitButton: props.showSaveAndExitButton,
            showSaveAndSkipButton: props.showSaveAndSkipButton,
            showSaveAndGoToSummaryButton:
                props.showSaveAndGoToSummaryButton && !props.sectionSpec.allow_multiple,
            showSkipButton:
                props.sectionSpec.allow_multiple &&
                props.isFirstLoad &&
                !props.sectionSpec?.multiple_sections?.hide_skip_button,
            title: `${props.sectionSpec.name} - ${props.collection.name}`,
            warningList
        });
    }, [props, params.index]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validateQuestions = () => {
        props.questions.forEach(question => {
            if (question.includeInApiPayload && question.ref.current != null) {
                question.ref.current.validate();
            }
        });
    };

    const validateSection = useCallback(async () => {
        await validateQuestions();
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [validateQuestions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isSectionValid = () => {
        let isValid = true;

        props.questions.forEach(question => {
            if (question.ref.current != null) {
                if (question.includeInApiPayload && !question.ref.current.isValid) {
                    isValid = false;
                }
            }
        });

        return isValid;
    };

    const createNewSubmission = useCallback(
        async payload => {
            payload.org_code = props.orgCode;

            const api = new Api();
            const response = await api.postSubmission(payload);

            props.onSubmissionIdChange(response?.data?.id);
        },
        [props, props.orgCode] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const updateExistingSubmission = useCallback(
        async payload => {
            const api = new Api();
            const id = props.submissionId;
            const multipleSectionIndex = state.multipleSectionIndex;

            if (props.sectionSpec.allow_multiple) {
                payload.section.index = multipleSectionIndex - 1;
            }
            await api.patchSubmission(payload, id);
        },
        [props, state.multipleSectionIndex] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const sendData = useCallback(async () => {
        const payload = {
            section: {
                id: props.sectionSpec.id,
                questions: getSubmissionSectionPayload(props.questions)
            }
        };
        if (props.submissionId) {
            await updateExistingSubmission(payload);
        } else {
            await createNewSubmission(payload);
        }
    }, [props, createNewSubmission, updateExistingSubmission]); // eslint-disable-line react-hooks/exhaustive-deps

    const submitData = useCallback(async () => {
        props.onSkipSections({
            sectionId: props.sectionSpec.id,
            nextSectionId: props.nextSectionId
        });

        await sendData();

        props.onSectionSubmit({
            index: state.multipleSectionIndex,
            sectionId: props.sectionSpec.id
        });
    }, [props, sendData, state.multipleSectionIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    const navigateTo = useCallback(
        path => {
            navigate(path);
            scrollToTopOfPage();
        },
        [navigate]
    );

    const navigateToNextSection = useCallback(() => {
        if (props.sectionSpec.allow_multiple) {
            if (props.sectionSpec?.multiple_sections?.enable_skip_to_add_another) {
                navigateTo(`${location.pathname}#add-another`);
                return;
            }
            const multipleSectionIndex = state.multipleSectionIndex;
            const multipleSectionCount = props.multipleSectionCount;

            if (multipleSectionIndex < multipleSectionCount) {
                const collectionUrlSlug = params.collection;
                const sectionId = params.section;

                navigateTo(`/${collectionUrlSlug}/${sectionId}/${multipleSectionIndex + 1}`);
            } else {
                navigateTo(`${location.pathname}#add-another`);
            }
        } else {
            navigateTo(props.nextPath);
        }
    }, [props, state.multipleSectionIndex, location.pathname, navigateTo, params]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = useCallback(
        async event => {
            event.preventDefault();
            await event.currentTarget.blur();

            await validateSection();

            if (isSectionValid()) {
                await submitData();
                navigateToNextSection();
            }
        },
        [validateSection, isSectionValid, submitData, navigateToNextSection] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleSaveAndExit = useCallback(
        async event => {
            event.preventDefault();
            await validateSection();

            if (isSectionValid()) {
                await submitData();
                props.onResetForm();
                navigateTo(`/${params.collection}`);
            }
        },
        [validateSection, isSectionValid, submitData, navigateTo, props, params] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleSaveAndSkipButton = useCallback(
        async event => {
            event.preventDefault();
            await event.currentTarget.blur();

            await validateSection();

            if (isSectionValid()) {
                await submitData();
                navigateTo(`/${params.collection}/summary`);
            }
        },
        [validateSection, isSectionValid, submitData, navigateTo, params] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleChange = useCallback(
        event => {
            props.onQuestionChange({
                index: state.multipleSectionIndex,
                sectionId: props.sectionSpec.id,
                questions: props.questions,
                ...event
            });

            const warningList = createSectionWarningListFromProps(props);
            setState(prevState => ({ ...prevState, warningList }));
        },
        [props, state.multipleSectionIndex] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleConditionalReveal = useCallback(
        event => {
            props.onConditionalReveal({
                index: state.multipleSectionIndex,
                sectionId: props.sectionSpec.id,
                ...event
            });
        },
        [props, state.multipleSectionIndex] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleDisable = useCallback(
        event => {
            props.handleDisable({
                index: state.multipleSectionIndex,
                sectionId: props.sectionSpec.id,
                ...event
            });
        },
        [props, state.multipleSectionIndex] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleSkip = useCallback(() => {
        props.onSkipSection({
            index: state.multipleSectionIndex,
            sectionId: props.sectionSpec.id
        });

        navigateTo(props.nextPath);
    }, [props, state.multipleSectionIndex, navigateTo]); // eslint-disable-line react-hooks/exhaustive-deps

    const navigateToAddOrAddAnother = useCallback(() => {
        const multipleSectionCount = props.multipleSectionCount;
        const sectionPath = `/${params.collection}/${params.section}`;

        if (multipleSectionCount === 1) {
            navigateTo(sectionPath);
        } else {
            navigateTo(`${sectionPath}/${multipleSectionCount - 1}#add-another`);
        }
    }, [props, navigateTo, params]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRemove = useCallback(async () => {
        const api = new Api();

        const id = props.submissionId;
        const sectionId = props.sectionSpec.id;
        const multipleSectionIndex = state.multipleSectionIndex;

        props.onRemoveSection({
            index: multipleSectionIndex,
            sectionId: sectionId
        });

        await api.deleteSubmissionSection(id, sectionId, multipleSectionIndex - 1);

        navigateToAddOrAddAnother();
    }, [props, state.multipleSectionIndex, navigateToAddOrAddAnother]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCopyAnswer = useCallback(
        data => {
            const index = state.multipleSectionIndex;
            if (index) {
                data.index = index;
            }
            return props.onCopyAnswer(data);
        },
        [props, state.multipleSectionIndex] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const enrichQuestionPresentation = question => {
        if (question.newQuestion) {
            return (
                <div>
                    <span style={{ color: '#005EB8' }}>
                        <b>[New Question]</b>
                    </span>
                </div>
            );
        }

        if (question.revisedQuestion) {
            return (
                <div>
                    <span style={{ color: '#005EB8' }}>
                        <b>[Revised Question]</b>
                    </span>
                </div>
            );
        }
    };

    useEffect(() => {
        setTitle(state.title);
    }, [state.title]);

    return (
        <FormSectionContainer sectionSpec={props.sectionSpec}>
            {!isEmpty(state.warningList) && (
                <WarningSummarySection warningList={state.warningList} />
            )}
            <form key={props.sectionSpec.id}>
                {props.questions
                    .filter(x => x.showOnFormSection)
                    .map((question, index) => (
                        <React.Fragment key={question.id}>
                            {enrichQuestionPresentation(question)}

                            <FormQuestions
                                isFirstLoad={props.isFirstLoad}
                                key={index}
                                onConditionalReveal={handleConditionalReveal}
                                onQuestionChange={handleChange}
                                onCopyAnswer={handleCopyAnswer}
                                orgCode={props.orgCode}
                                question={question}
                                questions={props.questions}
                                handleDisable={handleDisable}
                                // itemsRef={props.itemsRef}
                                ref={question.ref}
                            />
                        </React.Fragment>
                    ))}
                <hr />

                {state.showSaveAndGoToSummaryButton ? (
                    <Button onClick={handleSaveAndSkipButton} aria-label="Save and go to summary">
                        Save and go to summary
                    </Button>
                ) : (
                    <Button onClick={handleSubmit} aria-label="Save and Continue">
                        Save and Continue
                    </Button>
                )}

                {state.showSaveAndSkipButton && (
                    <Button
                        secondary={true}
                        className="nhsuk-u-margin-left-2"
                        onClick={handleSaveAndSkipButton}
                    >
                        Save and Skip to Summary
                    </Button>
                )}

                {state.showSaveAndExitButton && (
                    <Button
                        secondary={true}
                        className="nhsuk-u-margin-left-2"
                        onClick={handleSaveAndExit}
                    >
                        Save and Exit
                    </Button>
                )}

                {state.showSkipButton && (
                    <Button secondary={true} className="nhsuk-u-margin-left-2" onClick={handleSkip}>
                        Skip
                    </Button>
                )}

                {state.showRemoveButton && (
                    <Button
                        secondary={true}
                        className="nhsuk-u-margin-left-2"
                        onClick={handleRemove}
                        type="button"
                    >
                        Remove
                    </Button>
                )}
            </form>
        </FormSectionContainer>
    );
});

export default FormQuestionSection;
