import { Checkboxes, Fieldset } from 'nhsuk-react-components';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { FormQuestions } from '@form';

const Checkbox = forwardRef((props, ref) => {
    const [error, setError] = useState(undefined);
    const [isValid, setIsValid] = useState(undefined);

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const validate = () => {
        if (props.question.mandatory) {
            const value = getValue();

            if (!value.length) {
                setError('Please make a selection');
                setIsValid(false);
            } else {
                setError(undefined);
                setIsValid(true);
            }
        } else {
            setError(undefined);
            setIsValid(true);
        }
    };

    const getValue = () => {
        return props.question.value ?? [];
    };

    const handleChange = async event => {
        // This can go when we upgrade to React 17
        event.persist();

        const oldValue = getValue();
        const newValue = event.target.checked
            ? [...oldValue, event.target.value]
            : oldValue.filter(e => e !== event.target.value);

        await props.onChange({
            questionId: props.question.id,
            value: newValue
        });

        validate();

        const option = props.question.options.find(x => x.key === event.target.value);
        if (option && option.questions) {
            props.onConditionalReveal({
                isRevealed: event.target.checked,
                questionIds: option.questions.map(x => x.id)
            });
        }
    };

    useEffect(() => {
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.question.value]);

    const value = getValue();

    return (
        <Fieldset>
            <Fieldset.Legend>{props.question.name}</Fieldset.Legend>
            <Checkboxes hint={props.question.hint_text} name={props.question.name} error={error}>
                {props.question.options.map(option => (
                    <Checkboxes.Box
                        key={option.key}
                        value={option.key}
                        checked={value.includes(option.key)}
                        hint={option.hint_text}
                        onChange={handleChange}
                        required={props.question.mandatory}
                        ref={props.ref}
                        conditional={
                            option.questions
                                ? option.questions.map(question => (
                                      <FormQuestions
                                          question={props.questions.find(x => x.id === question.id)}
                                          key={question.id}
                                          onQuestionChange={props.onChange}
                                          ref={props.questions.find(x => x.id === question.id).ref}
                                      />
                                  ))
                                : undefined
                        }
                    >
                        {option.value}
                    </Checkboxes.Box>
                ))}
            </Checkboxes>
        </Fieldset>
    );
});

export default Checkbox;
