import axios from 'axios';
import { gmcSearch } from '@util/request-creator';
import { store } from 'app/store';

class Api {
    constructor() {
        const config = store.getState().config;

        this.baseUrl = config.apiBaseUrl;
        this.collection = this.getCollectionUrlSlug();
    }

    getCollection() {
        const path = `v2/collection/${this.collection}`;
        const url = new URL(path, this.baseUrl);

        return axios({
            method: 'get',
            url: url,
            headers: getHeaders()
        });
    }

    async getUserOrgCollection() {
        const path = `/v2/collections`;
        const url = new URL(path, this.baseUrl);

        return axios({
            method: 'get',
            url: url,
            headers: getHeaders()
        });
    }

    async getOrganisations() {
        const path = `v2/organisations/${this.collection}`;
        const url = new URL(path, this.baseUrl);

        const response = await axios({
            method: 'get',
            url: url,
            headers: getHeaders()
        });

        return response.data;
    }

    getCollectionUrlSlug() {
        const url = new URL(window.location.href);

        return url.pathname.split('/')[1];
    }

    async getIdentityProviders() {
        const path = 'v2/auth/identity-providers';
        const url = new URL(path, this.baseUrl);

        return axios({
            method: 'get',
            url: url
        });
    }

    getSubmissions(orgCode) {
        const path = `v2/submissions/${this.collection}?orgCode=${orgCode}`;
        const url = new URL(path, this.baseUrl);

        return axios({
            method: 'get',
            url: url,
            headers: getHeaders()
        });
    }

    async getSubmission(submissionId) {
        const path = `v2/submission/${this.collection}/${submissionId}/in-progress`;
        const url = new URL(path, this.baseUrl);

        const response = await axios({
            method: 'get',
            url: url,
            headers: getHeaders()
        });
        return response.data;
    }

    postSubmission(section) {
        const path = `v2/submission/${this.collection}`;
        const url = new URL(path, this.baseUrl);

        return axios({
            method: 'post',
            url: url,
            data: section,
            headers: getHeaders()
        });
    }

    async deleteSubmission(submissionId, deleteReason) {
        const path = `v2/submission/${this.collection}/${submissionId}`;
        const url = new URL(path, this.baseUrl);

        return axios.delete(url, {
            data: deleteReason,
            headers: getHeaders()
        });
    }

    async transferSubmission(submissionId, payload) {
        const path = `v2/submission/${this.collection}/${submissionId}/transfer`;
        const url = new URL(path, this.baseUrl);

        const response = await axios({
            method: 'post',
            url: url,
            data: payload,
            headers: getHeaders()
        });
        return response.data;
    }

    patchSubmission(section, id) {
        const path = `v2/submission/${this.collection}/${id}`;
        const url = new URL(path, this.baseUrl);
        return axios({
            method: 'patch',
            url: url,
            data: section,
            headers: getHeaders()
        });
    }

    putSubmission(sections, id) {
        const path = `v2/submission/${this.collection}/${id}`;
        const url = new URL(path, this.baseUrl);

        return axios({
            method: 'put',
            url: url,
            data: sections,
            headers: getHeaders()
        });
    }

    deleteSubmissionSection(id, sectionId, index) {
        const path = `v2/submission/${this.collection}/${id}/${sectionId}/${index}`;
        const url = new URL(path, this.baseUrl);

        return axios({
            method: 'delete',
            url: url,
            headers: getHeaders()
        });
    }

    getRefData(query) {
        const path = `v2/ref-data/gmc?query=${query}`;
        const url = new URL(path, this.baseUrl);
        const { accessToken } = store.getState().auth;

        return gmcSearch(url, accessToken);
    }
}

function getHeaders() {
    const { accessToken } = store.getState().auth;

    return {
        Authorization: `Bearer ${accessToken}`
    };
}

export default Api;
