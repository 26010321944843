import { forwardRef } from 'react';
import ReactHtmlParser from 'react-html-parser';

const TextBlock = forwardRef((props, ref) => {
    const html = atob(props.question.html);

    return ReactHtmlParser(html);
});

export default TextBlock;
