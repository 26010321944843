import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { getCurrentMoment, getMinMoment, getMoment } from '@util/date';
import { DateInput } from 'nhsuk-react-components';

const Date = forwardRef(({ question, onChange }, ref) => {
    const [error, setError] = useState(undefined);
    const [isValid, setIsValid] = useState(undefined);
    const [value, setValue] = useState(getValue());

    function getValue() {
        return (
            question.value ?? {
                day: '',
                month: '',
                year: ''
            }
        );
    }

    const validateDateInput = (dateInput, dateType) => {
        if (/^\d+$/.test(dateInput)) {
            switch (dateType) {
                case 'day':
                    value.day = dateInput.slice(0, 2);
                    break;
                case 'month':
                    value.month = dateInput.slice(0, 2);
                    break;
                case 'year':
                    value.year = dateInput.slice(0, 4);
                    break;
                default:
                    break;
            }
        } else if (/[^0-9]/.test(dateInput)) {
            switch (dateType) {
                case 'day':
                    value.day = dateInput.replace(/[^0-9]/g, '');
                    validateDateInput(value.day, 'day');
                    break;
                case 'month':
                    value.month = dateInput.replace(/[^0-9]/g, '');
                    validateDateInput(value.month, 'month');
                    break;
                case 'year':
                    value.year = dateInput.replace(/[^0-9]/g, '');
                    validateDateInput(value.year, 'year');
                    break;
                default:
                    break;
            }
        }
    };

    const validateDate = () => {
        const moment = getMoment(value);
        if (moment.isValid() && value.year) {
            setError(undefined);
            setIsValid(true);
            const minDate = question.min_date;
            const maxDate = question.max_date;
            const minAge = question.min_age;
            const questionName = question.name;
            if (minDate && maxDate && !isInDateRange()) {
                setError(`${questionName} must be between ${minDate} and ${maxDate}`);
                setIsValid(false);
            } else if (minDate && !isFutureDate()) {
                setError('Enter a later date for ' + questionName);
                setIsValid(false);
            } else if (maxDate && !isMaxDate()) {
                setError('Enter an earlier date for ' + questionName);
                setIsValid(false);
            } else if (question.only_allow_past_date && !isPastDate()) {
                setError('Future dates are not allowed for ' + questionName);
                setIsValid(false);
            } else if (minAge && !isMinimumAge()) {
                setError('The minimum required age for ' + questionName + ' is ' + minAge);
                setIsValid(false);
            }
        } else {
            setError('Please enter a valid date');
            setIsValid(false);
        }
    };

    const validate = () => {
        validateDateInput(value.day, 'day');
        validateDateInput(value.month, 'month');
        validateDateInput(value.year, 'year');
        if (question.mandatory) {
            if (value.day && value.month && value.year) {
                validateDate();
            } else {
                setError('Enter the ' + question.name);
                setIsValid(false);
            }
        } else {
            if (value.day || value.month || value.year) {
                validateDate();
            } else {
                setError(undefined);
                setIsValid(true);
            }
        }
    };

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const isPastDate = () => {
        return getMoment(value).isBefore(getCurrentMoment().subtract(1, 'days'));
    };

    const isFutureDate = () => {
        const dateParts = question.min_date.split('-');
        const minDate = getMoment({ day: dateParts[1], month: dateParts[2], year: dateParts[0] });
        return getMoment(value).isAfter(minDate);
    };

    const isMaxDate = () => {
        const dateParts = question.max_date.split('-');
        const maxDate = getMoment({ day: dateParts[1], month: dateParts[2], year: dateParts[0] });
        return getMoment(value).isBefore(maxDate);
    };

    const isInDateRange = () => {
        const maxDateParts = question.max_date.split('-');
        const maxDate = getMoment({
            day: maxDateParts[1],
            month: maxDateParts[2],
            year: maxDateParts[0]
        });

        const minDateParts = question.min_date.split('-');
        const minDate = getMoment({
            day: minDateParts[1],
            month: minDateParts[2],
            year: minDateParts[0]
        });

        return getMoment(value).isBetween(minDate, maxDate);
    };

    const isMinimumAge = () => {
        const minBirthdate = getMinMoment(question.min_age);
        return (
            getMoment(value).isValid() &&
            getMoment(value).isBefore(getCurrentMoment()) &&
            getMoment(value).isSameOrBefore(minBirthdate)
        );
    };

    const handleOnChange = async event => {
        const inputName = event.target.id.split('-').slice(-1);
        value[inputName] = event.target.value;
        setValue({ ...value });

        await onChange({
            questionId: question.id,
            value: value
        });

        validate();
    };

    return (
        <DateInput id={question.id} label={question.name} hint={question.hint_text} error={error}>
            <DateInput.Day
                value={value.day}
                onChange={handleOnChange}
                onBlur={handleOnChange}
                required={question.mandatory}
            />
            <DateInput.Month
                value={value.month}
                onChange={handleOnChange}
                onBlur={handleOnChange}
                required={question.mandatory}
            />
            <DateInput.Year
                value={value.year}
                onChange={handleOnChange}
                onBlur={handleOnChange}
                required={question.mandatory}
            />
        </DateInput>
    );
});

export default Date;
